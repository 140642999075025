<template>
  <div>
    <a-modal :visible.sync="show"
             width="1000px"
             title="退款详情"
             :footer="null"
             @cancel="$emit('update:show', false)">

      <div class="footer" style="margin-top:0;">
        <a-form-model :model="form"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">
          <!-- 申请内容 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item prop
                                 :labelCol="{ span: 2 }"
                                 label="申请理由">
                <span>{{infoData.refund_reason}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row style="margin-bottom: 10px;">
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="申请配图">
                <img v-for="item in imgList"
                     class="img-class"
                     :src="item.image_url"
                     :key="item.image_url"
                     alt
                     @click="handlerBigImg(item.image_url)" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <base-table ref="shopRef"
                  :customHeight="300"
                  :columnsData="invoiceColumns"
                  rowKey="id"
                  :tableData="goodsData">
          </base-table>

          <!-- 申请/审核 金额 -->
          <a-row>
            <a-col :span="12">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="申请金额">
                <span>{{infoData.apply_refund_amount}}元
                  <span v-if="Number(infoData.apply_refund_freight_amount)>0">（含运费{{infoData.apply_refund_freight_amount}}元）</span>
                </span>
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="退款金额">
                <span class="font-bold">{{infoData.audit_refund_amount}}元
                  <span v-if="Number(infoData.apply_refund_freight_amount)>0">（含运费{{infoData.apply_refund_freight_amount}}元）</span>
                </span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 客服 备注 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="审核备注">
                <span>{{infoData.audit_remarks}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 财务 备注 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="财务备注">
                <span>{{infoData.refund_remarks}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 财务 退款凭证 -->
          <a-row v-if="infoData.payment_voucher_image_url">
            <a-col :span="24">
              <a-form-model-item prop
                                 :labelCol="{ span: 2 }"
                                 label="退款凭证">
                <img class="img-class"
                     :src="infoData.payment_voucher_image_url"
                     alt
                     @click="handlerBigImg(infoData.payment_voucher_image_url)" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>

    <a-modal class="preview-class"
             title="退款图片"
             width="50%"
             :visible="previewVisible"
             :footer="null"
             @cancel="previewVisible = false">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { getRefundDetail } from '@/api/trading-manage/refund.js'
// import { formatGoodsType } from '@/utils/type.js'


export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    id: {
      type: [String, Number],
    },
  },
  data () {
    return {
      form: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      previewVisible: false,
      previewImage: '',
      invoiceColumns: [
        // {
        //   title: "商品分类",
        //   dataIndex: "goods_type",
        //   align: "center",
        //   width: "80px",
        //   slots: {
        //     customRender: 'type'
        //   },
        //   slotsType: 'format',
        //   slotsFunc: (val) => formatGoodsType(val)
        // },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "40%",
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          width: "30%",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          width: "30%",
        },
        {
          title: "数量",
          dataIndex: "number",
          align: "center",
          width: "80px",
        },
        {
          title: "商品总价",
          dataIndex: "total_price",
          align: "center",
          width: "80px",
        },
        {
          title: "是否退款",
          dataIndex: "is_refund",
          align: "center",
          width: "80px",
          slots: {
            customRender: "is_refund",
          },
          slotsType: "format",
          slotsFunc: (val) => {
            return val==2?"退款":"——"
          },
        },
      ],
      goodsData: [],
      imgList: [],
      img2List: [],
      infoData: {},
      formData: {
        id: this.id,
        payment_voucher_image_path: '',
        refund_remarks: ''
      }
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      getRefundDetail({ id: this.id }).then(res => {
        if (res.code === 0) {
          this.infoData = res.data

          let goodsData = []
          let goodsTotalPrice = 0
          res.data.suborder_list.map((el, j) => {
            //  (1-自提，2-配送，3-快递)
            if (el.order_type === 1) {
              this.formData.audit_pick_up_order_refund_amount = el.audit_refund_amount
            } else if (el.order_type === 2) {
              this.formData.audit_delivery_order_refund_amount = el.audit_refund_amount
            } else {
              this.formData.audit_express_order_refund_amount = el.audit_refund_amount
            }
            this.infoData.apply_refund_freight_amount = parseFloat(this.infoData.apply_refund_freight_amount || 0) + parseFloat(el.apply_refund_freight_amount)
            goodsData = goodsData.concat(el.goods_list.map((el, i) => {
              goodsTotalPrice += parseFloat(el.total_price)
              el.id = j + i
              return el
            }))
          })
          this.goodsData = goodsData
          this.infoData.origin_goods_amount = goodsTotalPrice
          this.infoData.apply_refund_goods_amount = this.infoData.apply_refund_amount - parseFloat(this.infoData.apply_refund_freight_amount)

          this.imgList = res.data.attached_image_list
          this.img2List = res.data.payment_voucher_image_url
        }
      })
    },

    handlerBigImg (e) {
      this.previewImage = e
      this.previewVisible = true
    },

    handlerCancel () {
      this.isShow = false
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>